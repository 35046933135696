// To do list project - madaleine osmun

import React from 'react'
import styled, { css } from 'styled-components'

export default function App () {
  const [task, setTask] = React.useState('')
  const [tasks, setTasks] = React.useState(() => {
    const str = window.localStorage.getItem('tasks') || '[]'
    return JSON.parse(str)
  })

  React.useEffect(() => {
    window.localStorage.setItem('tasks', JSON.stringify(tasks))
  }, [tasks])

  function createTask (task) {
    setTasks([
      ...tasks,
      { id: Math.floor(Math.random() * 99999), task, completed: false }
    ])
    setTask('')
  }

  return (
    <Container>
      <Title>Task Manager</Title>
      <Wrapper>
        <InputBox>
          <Input
            type='text'
            value={task}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                createTask(task)
              }
            }}
            onChange={e => setTask(e.target.value)}
          />
          <BtnMain
            onClick={() => {
              createTask(task)
            }}
          >
            +
          </BtnMain>
        </InputBox>
        <Ul>
          {tasks.map((t, i) => (
            <Item key={t.id}>
              <input
                type='checkbox'
                checked={t.completed}
                onChange={e => {
                  const newTasks = [...tasks]
                  newTasks[i].completed = e.target.checked
                  setTasks(newTasks)
                }}
              />

              <Text $completed={t.completed}>{t.task}</Text>

              <button
                onClick={() => {
                  const newTasks = [...tasks]
                  newTasks.splice(i, 1)
                  setTasks(newTasks)
                }}
                aria-label='Remove task'
              >
                &times;
              </button>
            </Item>
          ))}
        </Ul>
        {/* <pre>{JSON.stringify(tasks, null, 2)}</pre> */}
        {/* pre just puts it into pre formatted text */}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ede4ff;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 0 10px 1px rgba(97, 3, 246, 0.25);
  padding: 50px;
  min-width: 500px;
`

const Ul = styled.ul`
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  padding: 6px 0;
  list-style: none;
  display: flex;
`

const Text = styled.span`
  flex: 1;
  padding: 0 10px;
  ${p =>
    p.$completed &&
    css`
      text-decoration: line-through;
      color: gray;
    `}
`
const Title = styled.h1`
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  color: #3f3535;
`

const Input = styled.input`
  height: 50px;
  flex: 1;
  font-size: 30px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid black;
`

const BtnMain = styled.button`
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid black;
  border-left: none;
  background: #9548e5;
  padding: 0 10px;
  color: white;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    background-color: #8842d1;
  }
  &:active {
    background-color: #783ab9;
  }
`

const InputBox = styled.div`
  display: flex;
  padding-bottom: 16px;
`

// function Container ({ children }) {
//   //{}  == destructuring aka the quick way to get inside of objects or [] for arrays
//   return <div className='container'>{children}</div>
// }

// function Container (props) {
//   return <div className='container'>{props.children}</div>
// }
